import httpClient from '@/libs/http-client'

// 파트너 리스트
export function partnerList (data) {
  const url = '/api/admin/retail/list'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 파트너 정산요약
export function partnerSummryList (data) {
  const url = '/api/admin/retail/summryList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

// 파트너 정산 상세
export function partnerDetailList (data) {
  const url = '/api/admin/retail/detailList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 파트너 지급설정
export function partnerBalanceList (data) {
  const url = '/api/admin/retail/balanceList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 파트너 지급 상세
export function partnerBalanceDetail (data) {
  const url = '/api/admin/retail/balanceDetail'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 파트너 정산금 지급
export function partnerSetPoint (data) {
  const url = '/api/admin/retail/setPoint'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 파트너 설정 목록
export function partnerSettingList (data) {
  const url = '/api/admin/retail/settingList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 파트너 설정 저장
export function partnerSettingSave (data) {
  const url = '/api/admin/retail/settingSave'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerNewList (data) {
  const url = '/api/admin/retail/partnerList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerNewListb (data) {
  const url = '/api/admin/retail/partnerList2'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerNewSettingList (data) {
  const url = '/api/admin/retail/newPartnerList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerLevels (data) {
  const url = '/api/admin/partnerLevels'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerMemIds (data) {
  const url = '/api/admin/partnerMemIds'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerDetail (data) {
  const url = '/api/admin/retail/partnerDetail'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerModify (data) {
  const url = '/api/admin/retail/partnerModify'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerBlankSpinModify (data) {
  const url = '/api/admin/retail/updateBlankSpinRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerJoin (data) {
  const url = '/api/admin/retail/create'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerListRateChange (data) {
  const url = '/api/admin/retail/listUpdateRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerRateInfoList (data) {
  const url = '/api/admin/retail/rateInfoList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerRateInfoSave (data) {
  const url = '/api/admin/retail/rateInfoUpdate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerNewListRateChange (data) {
  const url = '/api/admin/retail/newListUpdateRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function getMaxRateByRecommendId (data) {
  const url = '/api/admin/partnerRates'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
//
// export function partnerLevels (params) {
//   const url = '/api/retail/partnerLevels'
//   return httpClient.post(url, params).then(response => {
//     return response
//   })
// }

export function creditIn (data) {
  const url = '/api/admin/partner/creditIn'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
// 관리자 출금
export function creditOut (data) {
  const url = '/api/admin/partner/creditOut'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function creditDetail (data) {
  const url = '/api/admin/credit/detailList'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function updateAllBlankSpinRate (data) {
  const url = '/api/admin/retail/updateAllBlankSpinRate'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function getSubpointListByDaily (data) {
  const url = '/api/admin/credit/subPoint/daily'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function setBottomMemberLock (data) {
  const url = '/api/admin/retail/userLockWithBottom'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}

export function partnerSettingCashInOut (data) {
  const url = '/api/admin/member/settingCashInOut'
  return httpClient.post(url, data).then(response => {
    return response.data
  })
}
